import React, { useState, useRef, useEffect } from "react";
import { ImageComponent } from "../../Components/Common/FallbackImage";
import ButtonComp from "../../Components/Ui/Button";
import InputComp from "../../Components/Ui/InputComp";
import SelectComp from "../../Components/Ui/SelectComp";
import TextareaComp from "../../Components/Ui/TextArea";
import ModalComp from "../../Components/Ui/ModalComp";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import {
  useAddProductMutation,
  useGetAllProductQuery,
  useGetCategoriesQuery,
  useUpdateProductMutation,
} from "../../store/Product/ProductApi";
import useFormValidator from "use-form-input-validator";
import { toast } from "react-toastify";
import { AiFillPlusCircle } from "react-icons/ai";
import AdminLayout from "../../Layout/AdminLayout";
import { useForm } from "react-hook-form";
import { uploadFileToS3 } from "../../Components/modules/Product/helper";

const EditProduct = () => {
  const { t } = useTranslation();

  const [productCsv, setProductCsv] = useState(false);
  const navigate = useNavigate();
  const [picture, setPicture] = useState(null);
  const [image, setImage] = useState(null);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = () => {
      if (reader.readyState === 2) {
        setImage(reader.result);
      }
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const currentUser = JSON.parse(localStorage.getItem("user_details_profile"));

  const { data: getCategories } = useGetCategoriesQuery();
  const {
    data: ProductData,
    isLoading: ProductLoader,
    isSuccess: ProductSuccess,
    refetch,
  } = useGetAllProductQuery();

  const fileInputRef = React.useRef(null);

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };
  const { values, errors, updateField, isAllFieldsValid } = useFormValidator({
    productName: { checks: "required", value: "" },
    productSku: { checks: "required", value: "" },
    composition: { checks: "required", value: "" },
    categoryId: { checks: "required", value: "" },
    // expiringDate: { checks: "required", value: "" },
    shelfLife: { checks: "required|num", value: "" },
    nafdacNo: { checks: "required", value: "" },
    productDescription: { checks: "required", value: "" },
    status: { checks: "required", value: "" },
    // productImageCheck: { checks: "required", value: "" },
    productPhotoUrl: { checks: "required", value: "" },
  });

  const [
    updateProduct,
    { isLoading: updateProductLoader, isSuccess: updateProductIsSuccess },
  ] = useUpdateProductMutation();

  const [myData, setMyData] = useState({});
  const { id } = useParams();
  const [inputPrice, setInputPrice] = useState({
    priceType: "wholesale",
    amount: 0,
    currency: "NGN",
  });
  const [inputPrice2, setInputPrice2] = useState({
    priceType: "retail",
    amount: 0,
    currency: "NGN",
  });
  const [inputPrice3, setInputPrice3] = useState({
    priceType: "hospital",
    amount: 0,
    currency: "NGN",
  });
  const [inputPrice4, setInputPrice4] = useState({
    priceType: "PPMV",
    amount: 0,
    currency: "NGN",
  });

  useEffect(() => {
    if (ProductData) {
      const tool = ProductData?.data?.find(
        (item) => Number(item?.id) === Number(id)
      );
      setMyData(tool);

      setInputPrice(
        tool?.prices?.find((item) => item?.priceType === "wholesale")
      );
      setInputPrice2(
        tool?.prices?.find((item) => item?.priceType === "retail")
      );
      setInputPrice3(
        tool?.prices?.find((item) => item?.priceType === "hospital")
      );
      setInputPrice4(tool?.prices?.find((item) => item?.priceType === "PPMV"));

      console.log(tool, "tool");
    }
  }, [ProductData]);

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setMyData({ ...myData, [name]: value });
  };

  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(null);
  const [uploadedFile, setUploadedFile] = useState(null);
  const handleUpload = async () => {
    if (!file) {
      toast.error("No File Selected");
      setLoading(false);
    } else {
      try {
        setLoading(true);
        const bucketName = process.env.REACT_APP_BUCKET_NAME;
        const folderName = currentUser?.user?.companyId; // Optional: Set a folder name in S3
        const fileUrl = await uploadFileToS3(file, bucketName, folderName);

        setLoading(false);
        if (fileUrl || !loading) {
          setUploadedFile(fileUrl);
          toast.success("File Uploaded Successfully");
        }
      } catch (error) {
        console.error("Error uploading file:", error?.message);
      }
    }
  };

  const handleMoneyChangeAll = (e, setData) => {
    setData((prevData) => ({
      ...prevData,
      amount: parseInt(e.target.value),
    }));
  };
  console.log(inputPrice?.amount, "First AMount");

  const handleUpdateProduct = () => {
    const one = [inputPrice, inputPrice2, inputPrice3, inputPrice4];

    console.log(one);

    const payload = {
      productName: myData?.productName,
      productSku: myData?.sku,
      composition: myData?.composition,
      productDescription: myData?.productDescription,
      categoryId: parseInt(myData?.categoryId),
      nafdacNo: myData?.nafdacNo,
      shelfLife: parseInt(myData?.shelfLife),
      status: myData?.status,
      productPhotoUrl: uploadedFile || myData?.productPhotoUrl,
      prices: one,
    };

    const id = myData?.id;

    updateProduct({ form: payload, id })
      .unwrap()
      .then((data) => {
        setMyData(data?.data);
        toast.success("Product Updated Successfully");
      })
      .catch((err) => {
        // console.log(err, "Error");
        if (Array.isArray(err?.data?.message)) {
          toast.error(err?.data?.message[0]);
        } else {
          toast.error(err?.data?.message);
        }
      });
  };

  const options = [
    { name: "Available", id: "Available" },
    // {name:'Discontinued',id:'discontinued '},
    { name: "Out of stock", id: "Out of stock" },
  ];

  return (
    <AdminLayout active={2}>
      <div className="flex justify-between pt-[2rem] items-start md:flex-row flex-col mb-5 gap-[1rem]">
        <h4
          className="flex gap-2 items-center font-semibold"
          onClick={() => navigate(-1)}
        >
          <ImageComponent src="/icon/back.svg" className="cursor-pointer" />
          Edit Product
        </h4>
        <ButtonComp
          onClick={() => setProductCsv(true)}
          btnClassName={"px-4 py-[10px] rounded-pill bg-primaryI text-white"}
          text={
            <span className="flex gap-2 ">
              <ImageComponent
                src="/icon/upload.png"
                width={24}
                height={24}
                className="cursor-pointer"
              />
              Product CSV File
            </span>
          }
        />
      </div>
      {/*  */}
      <div className="flex flex-wrap lg:flex-nowrap justify-between mb-5">
        <div className="w-full lg:w-[45%]">
          <div className="">
            <InputComp
              wrapperClassName="mb-3"
              labelClassName={"primaryI"}
              defaultValue={myData?.productName}
              label={t("lang.PN")}
              placeholder={myData?.productName}
              name="productName"
              onChange={handleOnChange}
              error={errors.productName}
            />
            <InputComp
              wrapperClassName="mb-3"
              labelClassName={"primaryI"}
              label={t("lang.PS")}
              defaultValue={myData?.productSku}
              placeholder={myData?.productSku}
              name="productSku"
              onChange={handleOnChange}
              error={errors.productSku}
            />
            <InputComp
              wrapperClassName="mb-3"
              labelClassName={"primaryI"}
              label={"Composition"}
              name="composition"
              defaultValue={myData?.composition}
              onChange={handleOnChange}
              error={errors.productComposition}
              placeholder={myData?.composition}
            />
            <SelectComp
              label={"Category"}
              labelClassName={"primaryI"}
              wrapperClassName="mb-3"
              name="categoryId"
              onChange={handleOnChange}
              error={errors.productCategory}
              options={getCategories?.data?.categories}
              defaultValue={myData?.categoryId}
            />
            <SelectComp
              label={t("lang.AS")}
              labelClassName={"primaryI"}
              wrapperClassName="mb-3"
              name="status"
              onChange={handleOnChange}
              defaultValue={myData?.status}
              error={errors.status}
              options={options}
            />
            <InputComp
              wrapperClassName="mb-3"
              labelClassName={"primaryI"}
              label={"FDA Registration Number"}
              name="nafdacNo"
              onChange={handleOnChange}
              error={errors.nafdacNo}
              defaultValue={myData?.nafdacNo}
              placeholder={myData?.nafdacNo}
            />
          </div>
        </div>
        <div className="col-lg-1 text-center flex justify-center">
          <div className="border-r-[1px] h-full border-[#4F008A]"></div>
        </div>
        <div className="w-full lg:w-[45%]">
          <div className="">
            <div className="item-container w-full flex items-center flex-col md:flex-row gap-[1rem]">
              <InputComp
                wrapperClassName="mb-3 w-full"
                labelClassName={"primaryI"}
                label="Product Price (Wholesaler)"
                placeholder={`4000`}
                name="wholesale"
                type="number"
                defaultValue={
                  myData?.prices?.find(
                    (item) => item?.priceType === "wholesale"
                  )?.amount
                }
                onChange={(e) => handleMoneyChangeAll(e, setInputPrice)}
                error={errors.productPrice}
              />

              <InputComp
                wrapperClassName="mb-3 w-full"
                labelClassName={"primaryI"}
                label="Product Price (Retailer)"
                placeholder={`4000`}
                name="retail"
                type="number"
                defaultValue={
                  myData?.prices?.find((item) => item?.priceType === "retail")
                    ?.amount
                }
                onChange={(e) => handleMoneyChangeAll(e, setInputPrice2)}
                error={errors.productPrice}
              />
            </div>

            <div className="item-container w-full flex items-center flex-col md:flex-row justify-between gap-[1rem]">
              <InputComp
                wrapperClassName="mb-3 w-full"
                labelClassName={"primaryI"}
                label="Product Price(Hospital)"
                placeholder={`4000`}
                name="hospital"
                type="number"
                defaultValue={
                  myData?.prices?.find((item) => item?.priceType === "hospital")
                    ?.amount
                }
                onChange={(e) => handleMoneyChangeAll(e, setInputPrice3)}
                error={errors.productPrice}
              />

              <InputComp
                wrapperClassName="mb-3 w-full"
                labelClassName={"primaryI"}
                label="Product Price(PPMV)"
                placeholder={`4000`}
                name="PPMV"
                type="number"
                defaultValue={
                  myData?.prices?.find((item) => item?.priceType === "PPMV")
                    ?.amount
                }
                onChange={(e) => handleMoneyChangeAll(e, setInputPrice4)}
                error={errors.productPrice}
              />
            </div>

            <InputComp
              wrapperClassName="mb-3"
              labelClassName={"primaryI"}
              label="Expiry Date"
              type="number"
              name="shelfLife"
              onChange={handleOnChange}
              error={errors.shelfLife}
              defaultValue={myData?.shelfLife}
              prefixIcon={
                <span>
                  <img src="/svgs/date.svg" alt="date icon" />
                </span>
              }
              placeholder={myData?.shelfLife}
            />

            <TextareaComp
              wrapperClassName="mb-4"
              name="productDescription"
              onChange={handleOnChange}
              error={errors?.productDescription}
              labelClassName={"primaryI"}
              label={t("lang.PD")}
              defaultValue={myData?.productDescription}
              placeholder={myData?.productDescription || "-"}
            />
            <div>
              <div className={`mb-[10px] font-medium primaryI`}>
                {"Product Image"}
              </div>
              <div className="flex gap-[10px] items-start justify-center md:flex-row flex-col  w-full">
                <div className="item_container flex items-center gap-[.8rem]">
                  <ImageComponent
                    src={
                      uploadedFile
                        ? uploadedFile
                        : image ||
                          myData?.productPhotoUrl ||
                          "/Images/noImage.png"
                    }
                    className="w-[64px] h-[64px]"
                  />
                  <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    accept="image/*"
                    name="productPhotoUrl"
                    // onChange={handleOnChange}
                    error={errors.productImage}
                    onChange={(e) => {
                      setPicture(e.target.files);
                      //convert Image to be display
                      handleImageChange(e);
                      updateField(e);
                      setFile(e.target.files[0]);
                    }}
                  />

                  <ButtonComp
                    loader={loading}
                    onClick={file ? handleUpload : handleButtonClick}
                    text={file ? "Upload Photo" : "Add Photo"}
                    btnClassName={
                      "py-[10px] bg-[#F6F2F9] text-[#282728] font-medium px-[1rem]"
                    }
                  />
                </div>
                <small className="text-[.8rem]">
                  Format Supported: *.jpeg and *.png
                </small>
              </div>
              {errors.productImageCheck && (
                <div className="text-danger text-start ps-1">
                  {"Picture"} {errors?.productImageCheck}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="text-center mb-5 flex justify-center items-center w-full">
        <ButtonComp
          // disabled={file || !updateProductIsSuccess}
          loader={updateProductLoader}
          onClick={handleUpdateProduct}
          text="Update Product"
          btnClassName={
            "bg-[#3DA84A] disabled:bg-gray-300 disabled:text-gray-400 text-white px-5 py-[10px] rounded-pill"
          }
        />
      </div>
      {/* <ModalComp
        show={showAddProductModal}
        bodyComponents={<AddProductModal navigate={navigate} />}
        handleClose={() => setShowAddProductModal(false)}
      />
      <ModalComp
        show={productCsv}
        size="lg"
        handleClose={() => setProductCsv(false)}
        bodyComponents={<AddProductCsv setProductCsv={setProductCsv} />}
      />
      <ModalComp
        show={showDynamicPricing}
        handleClose={() => setShowDynamicPricing(false)}
        size={"lg"}
        bodyComponents={
          <AddDynamicPricing setShowDynamicPricing={setShowDynamicPricing} />
        }
      /> */}
    </AdminLayout>
  );
};

export default EditProduct;

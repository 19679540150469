import React, { useEffect, useState } from "react";
import { useGetAllCustomerQuery } from "../../../../store/Customer/CustomerApi";
import { CustomerColumnsII } from "../../Customer/Data";
import { TableComp } from "../../../Common/TableComp";
import { Spinner } from "react-bootstrap";

const Customers = ({ id }) => {
  const {
    data: CustomerData,
    isLoading: CustomerLoader,
    isSuccess: CustomerSuccess,
  } = useGetAllCustomerQuery();
  const [repCustomers, setRepCustomers] = useState([]);
  useEffect(() => {
    if (CustomerSuccess) {
      let repCustomer = CustomerData?.data?.filter(
        (item) => Number(item?.createdById) === Number(id)
      );

      setRepCustomers(repCustomer);
      //   console.log(repCustomer);
    }
  }, [CustomerData]);
  return (
    <section>
      {CustomerLoader ? (
        <div className="spinner_container">
          <Spinner size="sm" />
        </div>
      ) : (
        <div className="item_container">
          <TableComp
            loader={CustomerLoader}
            Data={repCustomers?.reverse()}
            columnsComp={CustomerColumnsII(
              // tableDropDown,
              repCustomers?.length > 0 && [...repCustomers]?.reverse()
            )}
            pagination
          />
        </div>
      )}
    </section>
  );
};

export default Customers;

import React, { useEffect, useState } from "react";
import { TableComp } from "../../Common/TableComp";
import { useNavigate, useParams } from "react-router-dom";
import OffCanvasComp from "../../Ui/OffCanvas";
import ViewProductDetails from "./submodules/ViewProductDetails";
import TopHeader from "../../Common/TopHeader";
import { OrderColumns } from "./Data";
import SubHeader from "../../Common/SubHeader";
import { ImageComponent } from "../../Common/FallbackImage";
import ButtonComp from "../../Ui/Button";
import ModalComp from "../../Ui/ModalComp";
import TextareaComp from "../../Ui/TextArea";
import {
  useAcceptOrderMutation,
  useDeclineOrderMutation,
  useGetAllOrderQuery,
  useGetDebtorsReportQuery,
  useGetStockReportQuery,
} from "../../../store/Order/OrderApi";
import useFormValidator from "use-form-input-validator";
import { toast } from "react-toastify";
import { SortOrder } from "../../../utils/helper";
import moment from "moment";
import { formatMoney } from "../../../utils/formatMoney";
import ActivityReport from "./submodules/ActivityReport";
import StockReport from "./submodules/StockReport";
import PaymentDetails from "./submodules/PaymentDetails";
import DebtorProfile from "./submodules/DebtorProfile";
import { useFieldRepsQuery } from "../../../store/DashBoard/DashBoardApi";
import { CSVLink } from "react-csv";
import DateRangePicker from "./submodules/DateRangePicker";
import {
  useGetAllFieldRepActivitiesQuery,
  useGetAllFieldRepReportsQuery,
  useGetPaymentReportQuery,
} from "../../../store/Customer/CustomerApi";
import CsvDownloadBtn from "./submodules/CsvDownloadBtn";
import DateFilter from "../../Common/DateFilter";
import { Spinner } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default function SalesOrderComp() {
  const navigate = useNavigate();
  const { id } = useParams();
  // toast.error('hello')
  const [showCavans, setShowCavans] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [searchField, setSearchField] = useState("");
  const [orderList, setOrderList] = useState([]);
  const [orderData, setOrderData] = useState([]);
  const [orderReport, setOrderReport] = useState([]);

  const [total, setTotal] = useState(0);

  const {
    data: OrderData,
    isLoading: OrderDataLoader,
    isSuccess: OrderDataSuccess,
  } = useGetAllOrderQuery();

  // useEffect(() => {
  //   if (orderList?.length > 0) {
  //     let totalAmount = orderList
  //       .map((o) => o.total)
  //       .reduce((a, b) => parseFloat(a) + parseFloat(b), 0);

  //     setTotal(totalAmount);

  //     // console.log(orderList,'orderList')

  //     const _orderReport = SortOrder(orderList || []).map((o, idx) => {
  //       console.log(o, "0");
  //       return {
  //         No: orderList?.length - idx,
  //         OrderDate: moment(o.createdAt).format("MMM Do, YY"),
  //         FieldStaff: `${o.user?.firstName} ${o?.user?.lastName}`,
  //         Customer: `${o?.customer?.businessName || "-"}`,
  //         PaymentMethod: `${o?.payments[0]?.paymentMethod || "-"}`,
  //         PaymentStatus: `${o?.paymentStatus || "-"}`,
  //         Product: `${o?.orderItems[0]?.product?.productName}`,
  //         ProdQty: o?.orderItems[0]?.quantity,
  //         Amount: formatMoney(o.total),
  //         userId: o?.userId,
  //         createdAt: o?.createdAt,
  //       };
  //     });

  //     setOrderReport(_orderReport);
  //   }
  // }, [orderList]);

  const [
    declineOrder,
    { isLoading: declineOrderLoader, isSuccess: declineOrderSuccess },
  ] = useDeclineOrderMutation();
  const [
    AcceptOrder,
    { isLoading: acceptOrderLoader, isSuccess: acceptOrderSuccess },
  ] = useAcceptOrderMutation();

  useEffect(() => {
    if (OrderDataSuccess) {
      setOrderList(
        [...OrderData?.data?.order]?.sort(
          (a, b) => new Date(b?.createdAt) - new Date(a?.createdAt)
        )
      );
    }
  }, [OrderData?.data?.order, OrderDataSuccess]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isAllFieldsValid()) {
      const payload = {
        orderId: id,
        reason: values?.reason,
      };

      declineOrder(payload);
    }
  };
  const handleOrderAccept = (e) => {
    e.preventDefault();
    const payload = {
      orderId: id,
      // reason: values?.reason,
    };
    // console.log(payload, "payload");
    AcceptOrder(payload)
      .unwrap()
      .then((data) => {
        // console.log(data);
        toast.success("Success");
      })
      .catch((err) => {
        // console.log(err?.data,'err');
        toast.error(err?.data?.message);
      });
  };

  const { values, errors, updateField, isAllFieldsValid } = useFormValidator({
    reason: {
      checks: "required|reason is required",
      value: "",
      // msg:'reason is required'
    },
  });
  const {
    data: getFieldReps,
    isLoading,
    isSuccess,
    refetch,
  } = useFieldRepsQuery();

  const [activeTab, setActiveTab] = useState("Activities Report");
  const [repId, setRepId] = useState(null);
  const [repArray, setRepArray] = useState([]);

  useEffect(() => {
    setRepId(getFieldReps?.data?.users[0]?.id);
    setRepArray(getFieldReps?.data?.users);
  }, [getFieldReps]);

  const [repReport, setRepReport] = useState([]);
  const [toggleAside, setToggleAside] = useState(false);

  const [loading, setLoading] = useState(false);

  const handleShowReports = (rep) => {
    setRepId(rep?.id);

    const realReport = orderReport.filter((item) => item?.userId === repId);
    setRepReport(realReport);
    setToggleAside(true);
    // console.log(repReport, rep?.firstName);
  };

  const {
    data: getPaymentReport,
    isLoading: paymentLoading,
    isSuccess: paymentIsSuccess,
  } = useGetPaymentReportQuery(repId);

  const {
    data: getActivities,
    isLoading: activitiesLoading,
    isSuccess: activitiesSuccess,
    isFetching,
  } = useGetAllFieldRepReportsQuery(repId);

  const {
    data: getStock,
    isLoading: stocksLoading,
    isSuccess: stocksSuccess,
  } = useGetStockReportQuery(repId);

  console.log(getStock?.data, "stock Report");

  const {
    data: getProfile,
    isLoading: debtorLoading,
    isSuccess: debtorSuccess,
  } = useGetDebtorsReportQuery(repId);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [filteredData, setFilteredData] = useState(
    getActivities?.data?.reports
  );
  const [filteredPayment, setFilteredPayment] = useState(
    getPaymentReport?.data?.reports
  );
  const [filteredStock, setFilteredStock] = useState(getStock?.data?.reports);
  const [filteredDebtor, setFilteredDebtor] = useState(
    getProfile?.data?.reports
  );

  // console.log(getPaymentReport?.data?.reports, "data");
  // console.log(filteredPayment, "filtered Payment");

  const filterByDate = (items, startDate, endDate) => {
    return items?.filter((item) => {
      const itemDate = moment(item?.createdAt);
      return (
        itemDate.isSameOrAfter(moment(startDate)) &&
        itemDate.isSameOrBefore(moment(endDate))
      );
    });
  };

  useEffect(() => {
    if (startDate && endDate) {
      setFilteredData(
        filterByDate(getActivities?.data?.reports, startDate, endDate)
      );
      setFilteredPayment(
        filterByDate(getPaymentReport?.data?.reports, startDate, endDate)
      );
      setFilteredStock(filterByDate(getStock?.data, startDate, endDate));
      setFilteredDebtor(
        filterByDate(getProfile?.data?.reports, startDate, endDate)
      );
    } else {
      setFilteredData(getActivities?.data?.reports);
      setFilteredPayment(getPaymentReport?.data?.reports);
      setFilteredStock(getStock?.data);
      setFilteredDebtor(getProfile?.data?.reports);
    }
  }, [
    startDate,
    endDate,
    getActivities,
    getStock,
    getProfile,
    orderReport,
    getPaymentReport,
  ]);

  const tabs = [
    {
      name: "Activities Report",
      element: (
        <ActivityReport
          repId={repId}
          activitiesLoading={activitiesLoading}
          activitiesSuccess={activitiesSuccess}
          getActivities={filteredData}
          isFetching={isFetching}
        />
      ),
      data: getActivities?.data?.reports,
    },
    {
      name: "Stock Report",
      element: (
        <StockReport
          repId={repId}
          getStock={filteredStock}
          stocksLoading={stocksLoading}
          stocksSuccess={stocksSuccess}
        />
      ),
      data: getStock?.data,
    },
    {
      name: "Payment Details",
      element: (
        <PaymentDetails
          repId={repId}
          orderReport={filteredPayment}
          paymentLoading={paymentLoading}
        />
      ),
      data: orderReport,
    },
    {
      name: "Debtor's Profile",
      element: (
        <DebtorProfile
          repId={repId}
          getProfile={filteredDebtor}
          debtorSuccess={debtorSuccess}
          debtorLoading={debtorLoading}
        />
      ),
      data: getProfile?.data?.reports,
    },
  ];

  const ModalPage = [
    {
      name: "decline Order",
      component: (
        <DeclineOrderModal
          loader={declineOrderLoader}
          handleSubmit={handleSubmit}
          updateField={updateField}
          errors={errors}
          setShowModal={setShowModal}
        />
      ),
    },
    {
      name: "Accept Order",
      component: (
        <AcceptOrderModal
          loader={acceptOrderLoader}
          handleSubmit={handleOrderAccept}
          updateField={updateField}
          errors={errors}
          setShowModal={setShowModal}
        />
      ),
    },
  ];

  useEffect(() => {
    if (id) {
      setShowCavans(true);
    }
    // if (acceptOrderSuccess) {
    // }
  }, [id, acceptOrderSuccess]);

  // if (OrderDataSuccess)
  // console.log(orderList,'OrderData')
  // console.log([...OrderData?.data?.order]?.sort((a, b) => new Date(b?.createdAt) - new Date(a?.createdAt)),'OrderData')
  const handleSearch = (e) => {
    const newArray = getFieldReps?.data?.users?.filter(
      (item) =>
        item?.firstName.toLowerCase().match(e.target.value.toLowerCase()) ||
        item?.lastName.toLowerCase().match(e.target.value.toLowerCase())
    );

    setRepArray(newArray);
  };

  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);

  const formatDate = (date) => {
    return `${String(date.getDate()).padStart(2, "0")}/${String(
      date.getMonth() + 1
    ).padStart(2, "0")}/${date.getFullYear()}`;
  };

  if (isLoading) {
    return (
      <div>
        <Spinner size="md" />
      </div>
    );
  }

  return (
    <div className="">
      <div className="flex items-start justify-start w-full">
        {/* inner sidebar */}
        <div
          className={`aside_container ${
            toggleAside ? "custom:hidden" : "custom:block"
          } h-screen pb-[3rem] !overflow-y-scroll mx-auto min-w-[19rem] custom:w-full md:flex flex-col items-start justify-start border-r-[1px] custom:border-none border-gray-300`}
        >
          <div className="aside_title bg-[#4F008A]/3 w-full">
            <span className="section_title font-[600] text-black text-[1.4rem]">
              Field Reps
            </span>

            <div className="search-section mt-[2rem] mx-auto pr-[1rem] w-full flex items-center justify-center gap-[.8rem]">
              <div className="input_container w-full h-[3rem] flex items-center justify-center border-solid border-gray-300 border-[1px] rounded-[7px]">
                <div className="icon w-[20%] flex items-center justify-center">
                  <img src="/svgs/search.svg" alt="search-icon" />
                </div>
                <input
                  onChange={handleSearch}
                  type="text"
                  placeholder="Search for field rep"
                  className="w-[80%] h-full outline-none border-none text-[16px]"
                />
              </div>
              <button className="filter_container outline-none flex items-center justify-center h-[3rem] w-[3.5rem] rounded-[7px] border-solid border-[1px] border-[#4F008A]/15">
                <img src="/svgs/filter.svg" alt="" />
              </button>
            </div>
          </div>
          {/* aside */}
          <div className="fieldrep_section w-full">
            <div className="items-container flex flex-col md:pr-[1rem] pr-0 w-full gap-[1.5rem] mt-[1rem]">
              {repArray?.length > 0 ? (
                repArray?.map((item) => (
                  <div
                    className={`item_card border-solid ${
                      repId === item?.id && "bg-[#4F008A]/5"
                    }  border-[1px] p-[1rem] rounded-[5px] border-gray-200 w-full min-h-[10rem]`}
                    key={item?.id}
                  >
                    <div className="name_section flex items-center justify-start gap-[1rem]">
                      <div className="initials w-[3.5rem] h-[3.5rem] rounded-[7px] font-[600] text-black flex items-center justify-center bg-[#44AA43]/10">
                        {item?.firstName?.toUpperCase().charAt(0)}
                        {item?.lastName?.toUpperCase().charAt(0)}
                      </div>
                      <div className="name_section">
                        <span className="user_details block text-[.9rem] text-black font-[500]">
                          {item?.firstName} {item?.lastName}
                        </span>
                        <small className="location text-gray-300">
                          {item?.fullAddress || "-"}
                        </small>
                      </div>
                    </div>

                    <div className="report_section  mt-[1.4rem] w-full flex items-center justify-between">
                      <CsvDownloadBtn
                        data={
                          tabs?.find((tab) => tab?.name === activeTab)?.data
                        }
                      />
                      <span
                        onClick={() => handleShowReports(item)}
                        className="flex items-center text-[.75rem] justify-center gap-[.8rem] text-[#8354CF]"
                      >
                        View Reports
                        <img src="/svgs/greenArrow.svg" alt="" />
                      </span>
                    </div>
                  </div>
                ))
              ) : (
                <div className="item_container flex flex-col items-center justify-center text-center">
                  <span className="block">No Field Rep Yet</span>
                  <button
                    onClick={() => navigate("/console")}
                    className="w-[8rem] bg-green-800 text-white rounded-full h-[3rem] flex items-center justify-center"
                  >
                    Create One
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* main content */}
        <div
          className={`table_container ${
            toggleAside ? "custom:block" : "custom:hidden"
          } w-full md:px-[.5rem] h-screen overflow-y-scroll no-scrollbar`}
        >
          <div className="title_section font w-full flex items-center justify-start gap-[.5rem] mb-[1.5rem]">
            <div className="item_container custom:block hidden ml-[-0.01rem]">
              <button
                onClick={() => setToggleAside(false)}
                className="bg-[#4F008A] h-[1.2rem] w-[1.2rem] rounded-full flex items-center justify-center"
              >
                <img src="/svgs/arrow-back.svg" alt="" />
              </button>
            </div>
            <span className="block font-[600] text-[#4F008A] md:text-[1.35rem] text-[1rem]">
              {isLoading ? (
                "..."
              ) : getFieldReps?.data?.users.length > 0 ? (
                `${
                  getFieldReps?.data?.users?.find((item) => item?.id === repId)
                    ?.firstName
                } ${
                  getFieldReps?.data?.users?.find((item) => item?.id === repId)
                    ?.lastName
                }'s`
              ) : (
                <div className="item_container block  font-[600] text-[#4F008A] md:text-[1.35rem] text-[1rem]">
                  No Field Rep Report
                </div>
              )}
            </span>
            <span className="block font-[600] text-black md:text-[1.35rem] text-[1rem]">
              Sales Report
            </span>
          </div>
          <div className="tabs_date flex items-start  justify-between customXL:flex-row flex-col-reverse gap-[1rem] w-full">
            {/* tabs */}
            <div className="item_container overflow-x-scroll no-scrollbar w-full">
              <div className="nav_container md:w-[50%] min-w-[30rem] after:block after:w-full after:h-[.03rem] after:bg-[#4F008A]/10 mb-[1.9rem]">
                <div className="nav_section flex items-center justify-start gap-[1.5rem] w-full">
                  {tabs.map((item) => (
                    <div className="nav_pill" key={item.name}>
                      <button
                        onClick={() => setActiveTab(item.name)}
                        className={`md:text-[.9rem] text-[.75rem] pb-[1rem] ${
                          activeTab === item.name
                            ? "text-black font-[500] border-b-[2px] border-[#3DA84A]"
                            : " text-[#555555]"
                        }`}
                      >
                        {item.name}
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div className="flex gap-2">
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                placeholderText={formatDate(yesterday)}
                dateFormat="dd/MM/yyyy"
                className="form-control placeholder:!text-gray-400"
                selectsStart
                startDate={startDate}
                endDate={endDate}
              />
              <DatePicker
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                placeholderText={formatDate(today)}
                dateFormat="dd/MM/yyyy"
                className="form-control placeholder:!text-gray-400"
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
              />
            </div>
          </div>

          <div className="pages_container pb-[3rem]">
            {tabs.map((item) => {
              if (activeTab === item.name) {
                return item.element;
              }
            })}
          </div>
        </div>
      </div>
      <OffCanvasComp
        title={"Order Management"}
        show={showCavans}
        bodyComponent={
          <ViewProductDetails
            handleSubmit={() => setShowModal("decline Order")}
            handleUpdateOrder={() => setShowModal("Accept Order")}
            Data={OrderData?.data?.order?.find(
              (item) => Number(item?.id) === Number(id)
            )}
          />
        }
        handleClose={() => {
          setShowCavans(false);
          navigate(`/order_management`);
        }}
      />
      <ModalComp
        show={showModal}
        bodyComponents={
          ModalPage?.find((item) => item?.name === showModal)?.component
        }
        handleClose={() => setShowModal()}
      />
    </div>
  );
}

const DeclineOrderModal = ({
  handleSubmit,
  updateField,
  errors,
  loader,
  setShowModal,
}) => {
  return (
    <form>
      <div className="flex justify-center mb-[20px]">
        <ImageComponent src="/icon/box.svg" />
      </div>
      <div className="text-center mb-[30px] lg:mb-[62px]">
        <h5 className="font-semibold text-[18px] lg:text-[20px] mb-[20px] mx-[30px] lg:mx-[72px] leading-[32px] ">
          Are You Sure You Want To Decline This Order?
        </h5>
        <div className="mx-[50px]">
          <TextareaComp
            name="reason"
            onChange={updateField}
            error={errors.reason}
            rows={3}
            placeholder={"Add a comment..."}
          />
        </div>
      </div>
      <div className="row  mb-[64px] gap-[15px] justify-center">
        <div className="col-5">
          <ButtonComp
            text={"Cancel"}
            onClick={() => setShowModal()}
            btnClassName={
              "text-[14px] lg:text-[16px] w-full  py-[5px] lg:py-[13px]  px-[20px] lg:px-[34px] border-[0.64px] rounded-full border-[#D63434] text-[#D63434]"
            }
          />
        </div>
        <div className="col-5">
          <ButtonComp
            loader={loader}
            type="submit"
            onClick={handleSubmit}
            btnClassName={
              "text-[14px] lg:text-[16px] w-full py-[5px] lg:py-[13px] px-[25px] lg:px-[47px] border-[0.64px] rounded-full border-[#3DA84A] text-[#fff] bg-[#3DA84A]"
            }
            text={"Proceed"}
          />
        </div>
      </div>
    </form>
  );
};

const AcceptOrderModal = ({
  handleSubmit,
  updateField,
  errors,
  loader,
  setShowModal,
}) => {
  return (
    <form han>
      <div className="flex justify-center mb-[20px]">
        <ImageComponent src="/icon/box1.svg" />
      </div>
      <div className="text-center mb-[30px] lg:mb-[62px]">
        <h5 className="font-semibold text-[18px] lg:text-[20px] mb-[20px] mx-[30px] lg:mx-[72px] leading-[32px] ">
          Order Accepted Successfully
        </h5>
        <p className="text-[14px]">
          Your product has been successfully added and you can easily share the
          details with field reps
        </p>
        {/* <div className="mx-[50px]">
          <TextareaComp
            name="reason"
            onChange={updateField}
            error={errors.reason}
            rows={3}
            placeholder={"Add a comment..."}
          />
        </div> */}
      </div>
      <div className="row  mb-[64px] gap-[15px] justify-center">
        <div className="col-5">
          <ButtonComp
            text={"Cancel"}
            onClick={() => setShowModal()}
            btnClassName={
              "text-[14px] lg:text-[16px] w-full  py-[5px] lg:py-[13px]  px-[20px] lg:px-[34px] border-[0.64px] rounded-full border-[#D63434] text-[#D63434]"
            }
          />
        </div>
        <div className="col-5">
          <ButtonComp
            loader={loader}
            type="submit"
            onClick={handleSubmit}
            btnClassName={
              "text-[14px] lg:text-[16px] w-full py-[5px] lg:py-[13px] px-[25px] lg:px-[47px] border-[0.64px] rounded-full border-[#3DA84A] text-[#fff] bg-[#3DA84A]"
            }
            text={"Proceed"}
          />
        </div>
      </div>
    </form>
  );
};

import React, { useEffect, useState, useMemo } from "react";
import {
  useGetAllCustomerQuery,
  useGetAllFieldRepReportsQuery,
  useGetFieldRepLeadsQuery,
  useGetPaymentReportQuery,
  useGetScheduleQuery,
} from "../../../../store/Customer/CustomerApi";
import { Spinner, Alert } from "react-bootstrap";
import { useGetDebtorsReportQuery } from "../../../../store/Order/OrderApi";
import { currentDate, firstDateOfTheMonth } from "../../../../utils/helper";
import ReportsDownload from "../../../Common/ReportsDownload";

const useFetchData = (id) => {
  const activitiesQuery = useGetAllFieldRepReportsQuery(id);
  const paymentQuery = useGetPaymentReportQuery(id);
  const leadsQuery = useGetFieldRepLeadsQuery(id);
  const scheduleQuery = useGetScheduleQuery(
    { startDate: firstDateOfTheMonth(), endDate: currentDate },
    { skip: false, refetchOnMountOrArgChange: false }
  );
  const profileQuery = useGetDebtorsReportQuery(id);
  const customersQuery = useGetAllCustomerQuery();

  return {
    activitiesQuery,
    paymentQuery,
    leadsQuery,
    scheduleQuery,
    profileQuery,
    customersQuery,
  };
};

const Reports = ({ id }) => {
  const {
    activitiesQuery,
    paymentQuery,
    leadsQuery,
    scheduleQuery,
    profileQuery,
    customersQuery,
  } = useFetchData(id);

  const repCustomers = useMemo(() => {
    if (customersQuery.isSuccess) {
      return (
        customersQuery.data?.data?.filter(
          (item) => Number(item?.createdById) === Number(id)
        ) || []
      );
    }
    return [];
  }, [customersQuery.data, customersQuery.isSuccess, id]);

  const reportsData = useMemo(
    () => [
      { name: "Activities", data: activitiesQuery?.data?.data?.reports || [] },
      {
        name: "Schedule",
        data:
          scheduleQuery.data?.data?.filter(
            (item) => item.userId === Number(id)
          ) || [],
      },
      { name: "Payments", data: paymentQuery.data?.data?.reports || [] },
      { name: "Leads", data: leadsQuery.data?.data || [] },
      { name: "Customers", data: repCustomers || [] },
      { name: "Debtors", data: profileQuery?.data?.data?.reports || [] },
    ],
    [
      activitiesQuery?.data,
      scheduleQuery.data,
      paymentQuery.data,
      leadsQuery.data,
      profileQuery.data,
      repCustomers,
      id,
    ]
  );

  const isLoading =
    activitiesQuery.isLoading ||
    paymentQuery.isLoading ||
    leadsQuery.isLoading ||
    scheduleQuery.isLoading ||
    customersQuery.isLoading ||
    profileQuery.isLoading;

  const isSuccess =
    activitiesQuery?.isSuccess &&
    paymentQuery.isSuccess &&
    leadsQuery.isSuccess &&
    scheduleQuery.isSuccess &&
    customersQuery.isSuccess &&
    profileQuery.isSuccess;

  if (isLoading) {
    return <Spinner animation="border" variant="black" />;
  }

  if (!isSuccess) {
    return <Alert variant="danger">Failed to load data.</Alert>;
  }

  return (
    <div className="section_container">
      {reportsData.map((item, index) => (
        <ReportsDownload key={index} name={item.name} data={item.data} />
      ))}
    </div>
  );
};

export default Reports;

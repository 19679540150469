import { BsThreeDotsVertical } from "react-icons/bs";
import DropDownComp from "../../Ui/DropDown";
import moment from "moment";
import { statusStyleII } from "../Customer/Data";

export const ScheduleColumns = (tableDropDown, Data) => {
  return [
    {
      name: "ID",
      sortable: true,
      selector: (row) =>
        Data?.length > 0 && Data?.findIndex((item) => item?.id === row?.id) + 1,
      width: "70px",
    },

    {
      name: "Field Rep Name",
      sortable: true,
      selector: (row) => `${row?.user?.firstName} ${row?.user?.lastName}`,
    },

    {
      name: "Customer/Company",
      sortable: true,
      selector: (row) => row.customer?.businessName,
    },
    {
      name: "Time",
      sortable: true,
      selector: (row) => moment(row?.scheduleDate).format("MMM Do YY"),
    },
    {
      name: "Reason",
      sortable: true,
      selector: (row) => row.visitationReason,
    },
    {
      name: "Location",
      sortable: true,
      selector: (row) => row.customer?.address,
    },
    {
      name: "Status",
      sortable: true,
      selector: (row) => (
        <div
          className={`capitalize w-full text-center ${statusStyleII(
            row.status
          )}`}
        >
          {row.status}
        </div>
      ),
    },
    {
      name: "Action",
      // selector: (row) => <DropDownComp dropDownText={<BsThreeDotsVertical color="#7572EA" />}/>,
      cell: (row) => (
        <DropDownComp
          row={row}
          options={tableDropDown}
          dropDownText={<BsThreeDotsVertical color="#7572EA" />}
        />
      ),
    },
  ];
};

export const ScheduleColumnsII = (tableDropDown, Data) => {
  return [
    {
      name: "ID",
      sortable: true,
      selector: (row) => <div>{`#${row?.id}`}</div>,
      width: "70px",
    },

    {
      name: "Type Of Visit",
      sortable: true,
      selector: (row) => row.visitationReason,
    },

    {
      name: "Customer/Company",
      sortable: true,
      selector: (row) => row.customer?.businessName,
    },
    {
      name: "Time",
      sortable: true,
      selector: (row) => moment(row?.scheduleDate).format("MMM Do YY"),
    },
    {
      name: "Location",
      sortable: true,
      selector: (row) => row.customer?.address,
    },
    {
      name: "Status",
      sortable: true,
      selector: (row) => (
        <div
          className={`capitalize w-full text-center ${statusStyleII(
            row.status
          )}`}
        >
          {row.status}
        </div>
      ),
    },
  ];
};

export const CategoryData = [
  {
    name: (
      <span className="text-[14px] flex py-[4px] ">
        <div className="w-[23px] h-[23px] rounded-pill mr-[12px] bg-[#4F008A] text-[8px] flex justify-center items-center text-white">
          400
        </div>
        All
      </span>
    ),
  },
  {
    name: (
      <span className="text-[14px] flex text-[#656468] py-[4px] pr-[3rem] ">
        <div className="w-[23px] h-[23px] rounded-pill mr-[12px] bg-[#A67FC4] text-[8px] flex justify-center items-center text-white">
          400
        </div>
        Pharmacy Channel
      </span>
    ),
  },
  {
    name: (
      <span className="text-[14px] flex text-[#656468] py-[4px] ">
        <div className="w-[23px] h-[23px] rounded-pill mr-[12px] bg-[#A67FC4] text-[8px] flex justify-center items-center text-white">
          400
        </div>
        Wholesales Chan...
      </span>
    ),
  },
  {
    name: (
      <span className="text-[14px] flex text-[#656468] py-[4px] ">
        <div className="w-[23px] h-[23px] rounded-pill mr-[12px] bg-[#A67FC4] text-[8px] flex justify-center items-center text-white">
          400
        </div>
        GPO Channels
      </span>
    ),
  },
];

export const tabTop = [
  "Activities",
  "Schedule",
  "Payments",
  "Leads",
  "Reports",
];

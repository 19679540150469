import React, { useState, useEffect } from "react";
import {
  useGetAllCustomerActivitiesQuery,
  useGetAllCustomerReportQuery,
} from "../../../../store/Customer/CustomerApi";
import { Spinner } from "react-bootstrap";
import moment from "moment";
import { formatMoney } from "../../../../utils/formatMoney";

const CustomerActivity = ({ id, data, isLoading }) => {
  // const { data, isLoading, isSuccess } = useGetAllCustomerReportQuery(id);
  if (isLoading) {
    return (
      <div className="item_container flex items-center justify-center">
        <Spinner size="md" />
      </div>
    );
  }

  console.log(data?.data?.reports, "activities");
  return (
    <div>
      {data?.data?.reports?.length === 0 ? (
        <div className="item_container flex flex-col gap-[.7rem] mb-[8rem] items-center justify-center h-full w-full text-center">
          <img
            src="/svgs/noSchedule.svg"
            alt=""
            className="w-[5rem] h-[5rem]"
          />
          <span className="block">No Data</span>
        </div>
      ) : (
        <div className="item_container flex flex-col gap-[1.5rem]">
          {data?.data?.reports
            ?.slice()
            .reverse()
            .map((item) =>
              item?.reportType === "" ? null : (
                <div
                  className="item_card flex items-center justify-center gap-[1rem]"
                  key={item?.id}
                >
                  <div className="item_container">
                    <div className="image_container h-[3rem] w-[3rem] rounded-full bg-[#4F008A]/10 flex items-center justify-center">
                      <img
                        src={`/svgs/${
                          item?.reportType === "payment"
                            ? "payment"
                            : item?.reportType === "debtor"
                            ? "redpayment"
                            : item?.reportType === "customerActivities"
                            ? "visit"
                            : ""
                        }.svg`}
                        alt=""
                        className="md:h-[1.5rem] md:w-[1.5rem] h-[1.25rem] w-[1.25rem]"
                      />
                    </div>
                  </div>
                  <div className="text_container w-full flex flex-col items-start justify-between h-full">
                    <small className="block text-[.75rem] text-gray-400">
                      {moment(item?.createdAt).format("h a - MMMM Do YYYY")}
                    </small>
                    {item?.reportType === "payment" ? (
                      <span className="block text-[.8rem] font-[500] text-black">
                        {item?.amount
                          ? `${item?.paymentMethod} payment of ${formatMoney(
                              item?.amount,
                              true,
                              ""
                            )} ${
                              item?.balanceAmount !== null
                                ? `with balance of ${formatMoney(
                                    item?.balanceAmount
                                  )}`
                                : ""
                            } made by ${item?.customer?.businessName} at ${
                              item?.customer?.address
                            } with status of ${item?.paymentStatus}`
                          : `${item?.paymentMethod} payment made by ${item?.customer?.businessName} at ${item?.customer?.address} with status of ${item?.paymentStatus}`}
                      </span>
                    ) : item?.reportType === "debtor" ? (
                      <span className="block text-[.8rem] font-[500] text-black">
                        {item?.paymentStatus === "Part payment" ? (
                          <span className="block text-[.8rem] font-[500] text-black">
                            {`Balance payment of ${formatMoney(
                              item?.balanceAmount
                            )} for a ${item?.paymentStatus} of ${formatMoney(
                              item?.amount
                            )} made on the ${moment(
                              item?.payment?.paymentDate
                            ).format("Do MMMM, YYYY")}`}
                          </span>
                        ) : (
                          <span className="block text-[.8rem] font-[500] text-black">
                            {`Owing debt of ${formatMoney(
                              item?.balanceAmount
                            )} to be due on ${moment(
                              item?.payment?.paymentDate
                            ).format("Do MMMM, YYYY")}`}
                          </span>
                        )}
                      </span>
                    ) : item?.reportType === "customerActivities" ? (
                      <span className="block text-[.8rem] font-[500] text-black">{`Lead visit of ${item?.customer?.businessName} at ${item?.customer?.address}`}</span>
                    ) : null}
                  </div>
                </div>
              )
            )}
        </div>
      )}
    </div>
  );
};

export default CustomerActivity;

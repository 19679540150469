import React, { useEffect, useState, useCallback } from "react";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { CSVLink } from "react-csv";
import moment from "moment";
import ButtonComp from "../Common/ButtonComp";

const ReportsDownload = ({ name, data }) => {
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [filteredData, setFilteredData] = useState([]);

  const formatDate = (date) => {
    return `${String(date.getDate()).padStart(2, "0")}/${String(
      date.getMonth() + 1
    ).padStart(2, "0")}/${date.getFullYear()}`;
  };

  const filterDataByDate = useCallback(
    (data, startDate, endDate) => {
      if (!startDate || !endDate) {
        return data;
      }

      const start = moment(startDate).startOf("day");
      const end = moment(endDate).endOf("day");

      return data.filter((item) => {
        const date = moment(item.createdAt); // Ensure the date field is correct
        return date.isBetween(start, end, null, "[]");
      });
    },
    [startDate, endDate]
  );

  useEffect(() => {
    const flattenObject = (obj) => {
      const flattened = {};
      for (const key in obj) {
        if (typeof obj[key] === "object" && obj[key] !== null) {
          flattened[key] = JSON.stringify(obj[key]);
        } else {
          flattened[key] = obj[key];
        }
      }
      return flattened;
    };

    setFilteredData(
      filterDataByDate(data, startDate, endDate).map(flattenObject)
    );
  }, [startDate, endDate, data, filterDataByDate]);

  return (
    <section className="flex md:items-center custom:items-start md:justify-between custom:justify-center md:flex-row custom:flex-col md:w-[89%] w-full md:gap-[4rem] custom:gap-[1rem]">
      <div className="report_name w-[60%]">
        <span className="block font-semibold">{name}</span>
      </div>
      <div className="datepicker w-full">
        <div className="input_container md:px-[1rem] px-[.5rem] gap-[.5rem] flex items-center justify-center border-solid border-[1px] border-gray-200 rounded-[6px] h-[3rem]">
          <DatePicker
            selected={startDate}
            onChange={setStartDate}
            placeholderText={formatDate(yesterday)}
            dateFormat="dd/MM/yyyy"
            className="w-full"
            selectsStart
            startDate={startDate}
            endDate={endDate}
          />
          <DatePicker
            selected={endDate}
            onChange={setEndDate}
            placeholderText={formatDate(today)}
            dateFormat="dd/MM/yyyy"
            className="w-full"
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
          />
          <div className="item_container">
            <figure className="date-icon my-auto">
              <img
                src="/svgs/customerdate.svg"
                alt=""
                className="w-[3rem] h-[3rem]"
              />
            </figure>
          </div>
        </div>
      </div>
      <div className="button_container w-full">
        <CSVLink
          data={filteredData}
          filename={`${name}.csv`}
          className="btn w-full "
        >
          <ButtonComp
            text="Download"
            containerStyle={"bg-[#3DA84A] w-full text-white"}
            src={"/svgs/downloadReport.svg"}
          />
        </CSVLink>
      </div>
    </section>
  );
};

export default ReportsDownload;

import React from "react";
import { useGetDebtorsReportQuery } from "../../../../store/Order/OrderApi";
import { Spinner } from "react-bootstrap";
import moment from "moment";
import { formatMoney } from "../../../../utils/formatMoney";

const DebtorsReport = ({ id }) => {
  const {
    data: getProfile,
    isLoading,
    isSuccess,
  } = useGetDebtorsReportQuery(id);

  console.log(getProfile?.data?.reports, "debtor's");
  return (
    <div className="item_container">
      <div className="leads_container">
        {isLoading ? (
          <div className="spinner_container">
            <Spinner size="sm" />
          </div>
        ) : isSuccess && getProfile?.data?.reports?.length > 0 ? (
          <div className="flex flex-col w-full">
            {getProfile?.data?.reports
              ?.slice()
              .reverse()
              .map((item) => (
                <div
                  className="item_container  flex items-center justify-start mb-[2rem] gap-[1rem]"
                  key={item?.id}
                >
                  <div className="image_container w-[3rem] h-[3rem] p-[.7rem] rounded-full bg-[#4F008A]/10 flex items-center justify-center">
                    <img src="/svgs/redPayment.svg" alt="" />
                  </div>

                  <div className="text_container  w-full flex flex-col items-start justify-between h-full">
                    <small className="block text-[.75rem] text-gray-400">
                      {moment(item?.createdAt).format("h a - MMMM Do YYYY")}
                    </small>
                    <span className="block text-[.8rem]">
                      {item?.paymentStatus === "Paid" ? (
                        <div className="item_container text-[.8rem] font-semibold">
                          {`Debt payment of ${formatMoney(
                            item?.payment?.amount
                          )} by ${item?.customer?.businessName} via ${
                            item?.paymentMethod
                          } with status of ${item?.paymentStatus}`}
                        </div>
                      ) : (
                        <div className="item_container text-[.8rem] font-semibold">
                          {`Owing debt of ${formatMoney(
                            item?.balanceAmount
                          )} by ${item?.customer?.contactPersonName} of ${
                            item.customer?.businessName
                          } with status of ${item?.paymentStatus}`}
                        </div>
                      )}
                    </span>
                  </div>
                </div>
              ))}
          </div>
        ) : (
          <div className="item_container flex flex-col gap-[.7rem] mb-[8rem] items-center justify-center h-full w-full text-center">
            <img
              src="/svgs/noSchedule.svg"
              alt=""
              className="w-[5rem] h-[5rem]"
            />
            <span className="block">No Data</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default DebtorsReport;
